import React from "react";
import useTranslations from "../../../components/useTranslations";
import Footer from "../../../components/Footer/Footer";
import ExhibitionLeftNav from "../../../components/TwoColumnLeftNav/NewsLeftNav";
import LocalizedLink from "../../../components/localizedLink";

const Exhibition = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations
	const { exhibition } = useTranslations();

	const OfficeJaInfo = () => {
		return (
			<>
				<div className="exhibition-page two-column-left-nav">
					<div className="main-content">
						<div className="content-container">
							<h1 className="exhibition_h1">{exhibition}</h1>
							<div className="exhibition_inner">
								<div className="exhibition_title flex-container">
									<div className="exhibition_div_right">
										<a
											href="https://www.foomajapan.jp/"
											className="exhibition_eventname_link"
										>
											FOOMA JAPAN 2021（国際食品工業展）
										</a>
									</div>
									<div className="exhibition_div_left">
										<LocalizedLink to="/support/" class="btn_gray">
											ご質問・ご招待券はこちら
										</LocalizedLink>
									</div>
								</div>

								<div className="flex-container exhibition_content">
									<div className="exhibition_content_right">
										<ul>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">名称：</div>
												<div className="exhibition-li-content medium">
													<a
														href="https://www.foomajapan.jp/"
														className="exhibition-li-content medium"
													>
														FOOMA JAPAN 2021（国際食品工業展）
													</a>
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">会期：</div>
												<div className="exhibition-li-content medium">
													2021年6月1日（火）～2021年6月4日（金）
													<br />
													4日間　10：00～17：00
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">主催：</div>
												<div className="exhibition-li-content medium">
													一般財団法人 日本食品機械工業会
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">会場：</div>
												<div className="exhibition-li-content medium">
													愛知スカイエキスポ　Aichi Sky
													Expo（愛知県国際展示場）A～Fホール
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">弊社小間：</div>
												<div className="exhibition-li-content medium">
													B-645
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">
													主な出展商品：
												</div>
												<div className="exhibition-li-content medium">
													無線送信型デジタル温度計、HACCP機能付防水型デジタル温度計、防水型無線温度ロガー、糖度計、タイマー　など
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">入場料：</div>
												<div className="exhibition-li-content medium">
													1,000円（消費税込）
													※クイックパス登録（事前登録者）は無料
												</div>
											</li>
										</ul>
										<div className="exhibition-contents">
											<p>
												食品の生産現場から流通、店舗までに使用する食品機械・資材・備品関連商品の総合展示会です。
												<br />
												食品の製造・調理・流通に携わる方々に役立つ温度計各種を展示いたします。
												<br />
												新製品ブルートゥ－ス送信機能付中心温度計『SK-270WP-B』は、HACCP義務化に於ける作業者の負担軽減に役立つアイテムです。
												<br />
												また、衛生管理で必需品の手洗い専用タイマー、保管時の温度履歴を記憶する無線式温度データロガーなど、食品と温度に関わる各種計測器を多数出展いたします。
											</p>
										</div>
									</div>
								</div>
								<div className="exhibition_buttondiv">
									<LocalizedLink to="/support" class="btn_blue">
										ご質問・ご招待券はこちら
									</LocalizedLink>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	const OfficeEnInfo = () => {
		return (
			<>
				<div className="exhibition-page two-column-left-nav">
					<div className="main-content">
						<div className="content-container">
							<h1 className="exhibition_h1"> {exhibition} </h1>
							<div className="exhibition_inner">
								<div className="exhibition_title flex-container">
									<div className="exhibition_div_right">
										<a
											href="https://www.foomajapan.jp/english/"
											className="exhibition_eventname_link"
										>
											FOOMA JAPAN 2021 (International Food Industry Exhibition)
										</a>
									</div>
									<div className="exhibition_div_left">
										<LocalizedLink to="/support/mail" class="btn_gray">
											Click here for questions and invitation tickets
										</LocalizedLink>
									</div>
								</div>

								<div className="flex-container exhibition_content">
									<div className="exhibition_content_right">
										<ul>
											<li className="exhibition-li flex row">
												<div class Name="exhibition-li-title">
													Name:
												</div>
												<div className="exhibition-li-content medium">
													<a
														href="https://www.foomajapan.jp/english/"
														className="exhibition-li-content medium"
													>
														FOOMA JAPAN 2021 (International Food Industry
														Exhibition)
													</a>
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div class Name="exhibition-li-title">
													Session:
												</div>
												<div className="exhibition-li-content medium">
													June 1, 2021 (Tuesday) -June 4, 2021 (Friday)
													<br />4 days from 10:00 to 17:00
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div class Name="exhibition-li-title">
													Organizer:
												</div>
												<div className="exhibition-li-content medium">
													Japan Food Machinery Manufacturers Association
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div class Name="exhibition-li-title">
													Venue:
												</div>
												<div className="exhibition-li-content medium">
													Aichi Sky Expo Aichi Sky Expo (Aichi International
													Exhibition Center) Halls A to F
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div class Name="exhibition-li-title">
													Our booth:
												</div>
												<div className="exhibition-li-content medium">
													B-645
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">
													Main exhibited products:
												</div>
												<div className="exhibition-li-content medium">
													Wireless transmission type digital thermometer,
													waterproof digital thermometer with HACCP function,
													waterproof wireless temperature logger, sugar content
													meter, timer, etc.
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div class Name="exhibition-li-title">
													Admission:
												</div>
												<div className="exhibition-li-content medium">
													1,000 yen (tax included) * Quick pass registration
													(pre-registration) is free
												</div>
											</li>
										</ul>
										<div className="exhibition-contents">
											<p>
												This is a comprehensive exhibition of food machinery,
												materials, and equipment-related products used from food
												production sites to distribution and stores.
												<br />
												We will exhibit various thermometers that are useful for
												people involved in food manufacturing, cooking, and
												distribution.
												<br />
												The new product "SK-270WP-B", a central thermometer with
												a blue tooth transmission function, is an item that
												helps reduce the burden on workers when HACCP is
												mandatory.
												<br />
												In addition, we will exhibit a large number of various
												measuring instruments related to food and temperature,
												such as a timer dedicated to hand washing, which is an
												essential item for hygiene management, and a wireless
												temperature data logger that stores the temperature
												history during storage.
											</p>
										</div>
									</div>
								</div>
								<div className="exhibition_buttondiv">
									<LocalizedLink to="/support/mail" class="btn_blue">
										Click here for questions and invitation tickets
									</LocalizedLink>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	const pathName =
		typeof window !== "undefined" ? window.location.pathname : "";

	return (
		<>
			<article className="company-info two-column-left-nav">
				<ExhibitionLeftNav />
				<section className="main-content">
					{pathName.indexOf("/en") !== -1 ? <OfficeEnInfo /> : <OfficeJaInfo />}
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Exhibition;
