import React from "react"
import LocalizedLink from "../localizedLink"
import useTranslations from "../useTranslations"

const NewsLeftNav = () => {
    const ActiveStyle = {
        color: '#0428a0'
    }
    const {news, events, campaign, exhibition, allNews, newProducts, products, notice} = useTranslations()
    return (
        <>
            <div className="leftnav news-leftnav">
                <div className="ac pc leftnav-links">
                    <input className="ac-input" id="news-info-pc" name="news-info" type="checkbox"/>
                    <label className="ac-label leftnav-link" htmlFor="news-info-pc"><span>{news}</span></label>
                    <section className={`ac-text`}>
                        <ul >
                            <li className="leftnav-link-child">
                                <LocalizedLink activeStyle={ActiveStyle} to={`/news/newProduct`} >{newProducts}</LocalizedLink>
                            </li>
                            <li className="leftnav-link-child">
                                <LocalizedLink activeStyle={ActiveStyle} to={`/news/product`} >{products}</LocalizedLink>
                            </li>
                            <li className="leftnav-link-child">
                                <LocalizedLink  activeStyle={ActiveStyle} to={`/news/campaign`} >{campaign}</LocalizedLink>
                            </li>
                            <li className="leftnav-link-child">
                                <LocalizedLink  activeStyle={ActiveStyle} to={`/news/event`} >{events}</LocalizedLink>
                            </li>
                            <li className="leftnav-link-child">
                                <LocalizedLink  activeStyle={ActiveStyle} to={`/news/notice`} >{notice}</LocalizedLink>
                            </li>
                            <li className="leftnav-link-child">
                                <LocalizedLink  activeStyle={ActiveStyle} to={`/news`} >{allNews}</LocalizedLink>
                            </li>
                        </ul>
                    </section>
                    <li className="leftnav-link">
                        <LocalizedLink  activeStyle={ActiveStyle} to={`/news/exhibition`} >{exhibition}</LocalizedLink>
                    </li>
                </div>


                <div className={`ac sp`}>
                    <input className="ac-input" id="news-info" name="news-info" type="checkbox"/>
                    <label className="ac-label" htmlFor="news-info">{news}</label>
                    <section className={` ac-text ac-text-footer `}>
                        <ul className="leftnav-links">
                            <li className="leftnav-link">
                                <LocalizedLink  to={`/news`} >{allNews}</LocalizedLink>
                            </li>
                            <li className="leftnav-link">
                                <LocalizedLink to={`/news/newProduct`} >{newProducts}</LocalizedLink>
                            </li>
                            <li className="leftnav-link">
                                <LocalizedLink  to={`/news/campaign`} >{campaign}</LocalizedLink>
                            </li>
                            <li className="leftnav-link">
                                <LocalizedLink  to={`/news/event`} >{events}</LocalizedLink>
                            </li>
                        </ul>
                    </section>
                    <li className="leftnav-link">
                        <LocalizedLink  to={`/news/exhibition`} >{exhibition}</LocalizedLink>
                    </li>
                </div>
            </div>
        </>
    )
}

export default NewsLeftNav

